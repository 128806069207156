<template>
  <div class="row mx-0">
    <div class="col-12 py-2 px-1 p-md-3" :class="{'lightgrey_bg': isOdd}">
      <div class="row m-0 align-items-center">
        <div class="col-6 col-md-3 p-0 text-center font15">
          {{item.donorGroupName}}
        </div>
        <div class="col-md-2 p-0 text-center font15 d-none d-md-block">
          {{item.groupFounder.firstName}} {{item.groupFounder.lastName}}
        </div>
        <div class="col col-md-2 p-0 text-center font15">
          {{date}}
        </div>
        <div class="col-md p-0 text-center d-none d-md-block">
          <div class="row mx-0 align-items-center justify-content-end">
            <div class="col px-1">
              <Button color="green" btnText="View Group Members" size="small" icon="arrow" width="100" @click="viewMembers">
                <IconGroups size="size16" />
              </Button>
            </div>
            <div class="col px-1">
              <Button color="gold" btnText="Edit Group Name" size="small" icon="arrow" width="100" @click="edit">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey_red" icon="arrow" size="xxsmall" @click="deleteGroup">
                <ios-close-icon class="font19 inline-icon" />
              </Button>
            </div>
          </div>
        </div>
        <div class="col p-0 text-center d-md-none">
          <Button color="green" btnText="View" size="small" @click="view" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconGroups: defineAsyncComponent(() => import('../components/icons/IconGroups.vue')),
    IconEdit: defineAsyncComponent(() => import('../components/icons/IconEdit.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'GroupsManagedItem',
  props: ['item', 'thekey'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false
    }
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.creationDate))
      return date.toLocaleString(DateTime.DATE_SHORT)
    },
    isOdd () {
      return this.thekey % 2
    }
  },
  methods: {
    view () {
      this.$emit('showDetails')
    },
    viewMembers () {
      this.$emit('showMembers')
    },
    edit () {
      this.$emit('editName')
    },
    deleteGroup () {
      this.$emit('deleteGroup')
    }
  }
}
</script>

<style scoped>
.font15 {
  font-size: 13px;
}
@media (min-width: 992px) {
  .font15 {
    font-size: 15px;
  }
}
</style>
